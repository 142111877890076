import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header/Header";
import { Space } from "antd";
import Installation from "./components/Installation/Installation";
import CarouselApp from "./components/Carousel/Carousel";

function App() {
  return <Header />;
}

export default App;
