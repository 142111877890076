import React from "react";
import "./Header.css";
import image1 from "../../assets/logosplash.png";

export default function Header() {
  return (
    <div className="simple" justify>
      <img  src={image1} className="img" alt="Logo de l'application"/>

      <p className="downloadTextTitle">TÉLÉCHARGER L'APPLICATION</p>
      <a href="/GSLibrary.apk" download>
        <div className="buttonAndroid">
          <p className="downloadText">Cliquer ici pour télécharger</p>
        </div>
      </a>
    </div>
  );
}
